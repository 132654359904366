import * as React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby";
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import PageLayout from '../layout/page-layout';

const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const GatsbyLink = styled(Link)`
  text-decoration: none;
`

const NotFoundPage = () => (
  <PageLayout>
    <AppBar position="sticky">
      <Paper square>
        <CenterContainer>
          <h1>Bootstrap Gamer Aids</h1>
        </CenterContainer>
      </Paper>
    </AppBar>
    <div>
      <GatsbyLink to="/">
        <MaterialLink color="primary" component="span">Home</MaterialLink>
      </GatsbyLink>
    </div>
    <div>404 - Page Not Found</div>
  </PageLayout>
)

export default NotFoundPage
